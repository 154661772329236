import React            from 'react'
import CtaButton        from './CtaButton'
import Styles           from './LoveHoroscopeSection.module.css'


const LoveHoroscopeSection = () => {

    return (
    <>
    <section id="LoveHoroscopeSection" className={Styles.loveHoroscopeSection}>

        <div className={Styles.content}>
            {/* Left Column */}
            <div className={Styles.imageContainer}>
                <h2>StarGoddess Love Horoscope Reading</h2>
                <h3>Is love in the air? Find out..</h3>
                <img src="/img/lovers.jpg" alt="StarGoddess Love Horoscope Reading" className={Styles.loveImage} loading="lazy" />
            </div>

            {/* Right Column */}
            <div className={Styles.textContainer}>
                <p>
                Unlock the secrets of your astrological compatibility and discover the magic
                between you and your partner.
                </p>

                <p>
                Experience the magic of personalized love horoscope readings with StarGoddess!
                Discover the unique strengths that bring you and your partner closer, and uncover
                potential challenges before they arise. Our insights will guide you towards a more
                harmonious and fulfilling relationship, lighting the way to a love that's written
                in the stars.
                </p>

                <CtaButton link='/love-horoscope-reading' linkText='Find Out More' />
            </div>
        </div>

    </section>
    </>
    );
};


export default LoveHoroscopeSection;
