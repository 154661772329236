import React from 'react'
import { HashLink } from 'react-router-hash-link'

// <li><HashLink smooth to="/#PersonalReadingSection"  onClick={toggleMenu}>Personal</HashLink></li>

const DefaultMenu = ( { toggleMenu } ) => {
    return (
        <>
        <li><HashLink smooth to="/#HeroSection"             onClick={toggleMenu}>Home</HashLink></li>
        <li><HashLink smooth to="/#ForecastSection"         onClick={toggleMenu}>Daily Horoscopes</HashLink></li>
        <li><HashLink smooth to="/#LoveHoroscopeSection"    onClick={toggleMenu}>Love Horoscope</HashLink></li>
        <li><HashLink smooth to="/#YearAheadReadingSection" onClick={toggleMenu}>Year-Ahead</HashLink></li>
        <li><HashLink smooth to="/#StarSignsSection"        onClick={toggleMenu}>Star Signs</HashLink></li>
        <li><HashLink smooth to="/#TarotSection"            onClick={toggleMenu}>Tarot Readings</HashLink></li>

        <li><HashLink smooth to="/#AboutSection"            onClick={toggleMenu}>About</HashLink></li>
        <li><HashLink smooth to="/#commonQuestions"         onClick={toggleMenu}>Common Questions</HashLink></li>
        </>
    )
}

export default DefaultMenu
