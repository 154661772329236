import React        from 'react'
import CtaButton    from './CtaButton'
import Styles       from './YearAheadSection.module.css'

const YearAheadSection = () => {
    return (
        <section id="YearAheadReadingSection" className={Styles.yearAheadSection}>

            <div className={Styles.content}>
                <div className={Styles.imageContainer}>
                    <h2>My Personalised Year-Ahead Horoscope Reading for You</h2>
                    <h3>Let me reveal what lies ahead for you</h3>
                    <img
                        src="/img/year-ahead-crystal-ball.jpg"
                        alt="A universe in a crystal ball"
                        className={Styles.image}
                        loading="lazy"
                    />
                </div>

                {/* Right Column - Text and CTA Button */}
                <div className={Styles.textContainer}>
                    <p>
                        Allow me to craft a unique and personal forecast for your year ahead.
                        Using the exact place, time, and date of your birth, I'll unveil what
                        the stars have in store just for you.
                    </p>
                    <p>
                        Together, we'll explore key insights about your career, love life, health, and more.
                        With my celestial guidance, you'll step into the future with clarity and confidence.
                    </p>

                    <CtaButton link='/year-ahead-reading' linkText='Let’s Begin Your Journey' />
                </div>
            </div>
        </section>
    );
};

export default YearAheadSection
