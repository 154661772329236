import React        from 'react'
import CtaButton    from './CtaButton'
import Styles       from './StarsignsSection.module.css'


const StarsignsSection = () => {

    return (
        <>
        <section id="StarSignsSection" className={Styles.starsignsSection}>

            <h2>Star Signs of the Zodiac</h2>

            <div className={Styles.content}>

                <div className={Styles.textContainer}>
                    <p><em>Look up, wanderer</em>, find your place among the stars in the grand Zodiac tapestry that binds us all.</p>
                    <p>The Zodiac is a great celestial band that encircles our sky like a jeweled belt, glittering with stories and secrets.  This belt is the Sun's grand path across the heavens, splitting neatly into twelve equal realms.  Each realm a star sign, weaving its own unique energies and stories across the cosmos.</p>
                    <p>Each sign whispers the deep secrets of the universe, from the fiery courage of Aries to the mystical waters of Pisces.</p>
                    <p>Together, they form a circle of celestial influence that touches every life, weaving the threads of destiny that connect the heavens and Earth.</p>

                    <CtaButton link='/star-signs' linkText='Learn about Your Star Sign' />

                </div>


                <div className={Styles.imageContainer}>
                    <img className={Styles.mainImage} src="/img/zodiac-starfield.jpg" alt="beautiful, long-exposure startrails" loading="lazy" />
                </div>
            </div>

        </section>
        </>
    );
};

export default StarsignsSection;
