import React, { useState, useEffect, useRef, useCallback, useMemo }  from 'react'
import { useParams }                from 'react-router-dom'
import { Helmet }                   from 'react-helmet'
import axios                        from 'axios'
import CmsContent                   from '../components/CmsContent'
import Spinner                      from '../components/Spinner'

import { console_error }            from '../utils/utils'

import styles from './TarotPage.module.css'


const TarotPage = ({page}) => {

    const topRef                            = useRef(null);
    const { suit, cardId }                  = useParams();
    const [contentLoaded, setContentLoaded] = useState(false);

    const pages = useMemo(
        () => ({
            Null: {},
            Index: {
                title:              'The Mysteries of the Tarot',
                metaDescription:    'unlocking the wisdom of the cards',
                slug:               '/the-tarot',
                websiteUrl:         'https://www.stargoddess.co/tarot',
            },
            Guide: {
                title:              'The Tarot: A Beginner\'s Guide',
                metaDescription:    'a beginners guide to tarot - unlocking the wisdom of the cards',
                slug:               '/an-introduction-to-tarot-unlocking-the-wisdom-of-the-cards',
                websiteUrl:         'https://www.stargoddess.co/tarot/guide',
            },
            Readings: {
                title:              '',
                metaDescription:    '',
                slug:               '',
                websiteUrl:         '',
            },
            MajorArcanaIndex: {
                title:              'The Major Arcana - A Journey Through the Tarot’s Mystical Heart',
                metaDescription:    'Explore the 22 cards of the Major Arcana, each a chapter in the timeless journey of self-discovery and wisdom.',
                slug:               '/the-major-arcana-a-journey-through-the-tarots-mystical-heart',
                websiteUrl:         'https://www.stargoddess.co/tarot/major-arcana',
            },
            MinorArcanaIndex: {
                title:              'Introducing the Minor Arcana - The Foundation of Everyday Magic',
                metaDescription:    'StarGoddess invites you to discover the Minor Arcana: 56 cards revealing the magic in everyday life, guiding you through personal growth, relationships, and daily challenges.',
                slug:               '/introducing-the-minor-arcana-the-foundation-of-everyday-magic',
                websiteUrl:         'https://www.stargoddess.co/tarot/minor-arcana',
            },
        }),
        []
    );


    const [pageData, setPageData] = useState(pages.Null);



    const fetchArcanaByKey = async (key) => {

        let requestUri;

        try
        {
            requestUri =
                `${process.env.REACT_APP_API_ENDPOINT}/api/tarot/arcana?key=${key}`;

            const response =
                await axios.get(requestUri);

            return response.data;
        }
        catch (error)
        {
            console_error(
                `fetchArcana failed: for key ${key}\nrequest uri: ${requestUri}\nError: ${JSON.stringify(error)}\n`);
        }

        return null;
    };

    useEffect(() => {
        const selectPage = async () => {

            setPageData(pages.Null);

            switch (page)
            {
                case "Index":
                    return pages.Index;

                case "Guide":
                    return pages.Guide;

                case "MajorArcana":
                    return (await fetchArcanaByKey(cardId)) || pages.MajorArcanaIndex;

                case "MinorArcana":
                    if (suit && ! cardId)
                    {
                        // cupsIndex, pentaclesIndex, swordsIndex, wandsIndex
                    }
                    else if (suit && cardId)
                    {
                        return await fetchArcanaByKey(cardId) || pages.MinorArcanaIndex;
                    }
                    else
                    {
                        return pages.MinorArcanaIndex;
                    }
                    break;

                default:
                    return pages.Index;
            }
        };

        const loadPageData = async () => {
            const data = await selectPage();
            setPageData(data);
        };

        loadPageData();
    }, [page, suit, cardId, pages]);

    const handleContentLoaded = useCallback(() => {
        setContentLoaded(true);
    }, []);


    useEffect(() => {
        setContentLoaded(false);
    }, [pageData.slug]);

    useEffect(() => {
        if (contentLoaded) {
            topRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [contentLoaded]);

    const { title, metaDescription, slug, websiteUrl } = pageData;


    return (
    <>
        {pageData === pages.Null ? (
            <>
                <div className={styles.page}>
                    <div className={styles.content}>
                        <Spinner sizeClass="medium" />
                    </div>
                </div>
            </>
            ) : (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={metaDescription} />
                    <meta property="og:url"  content={websiteUrl} />
                    <link rel="canonical"    href={websiteUrl} />
                </Helmet>

                <div ref={topRef}></div>

                <div className={styles.page}>
                    <div className={styles.content}>
                        <CmsContent type="post" slug={slug} onContentLoaded={handleContentLoaded} />
                    </div>
                </div>
            </>
        )}
    </>
    );
};

export default TarotPage;
