import React        from 'react'
import { HashLink } from 'react-router-hash-link'
import { Link }     from 'react-router-dom'

// <li><HashLink smooth to="/#PersonalReadingSection"  onClick={toggleMenu}>Personal</HashLink></li>

const UserMenu = ( { toggleMenu, handleSignOut } ) => {
    return (
    <>
        <li><HashLink smooth to="/#HeroSection"             onClick={toggleMenu}>Home</HashLink></li>
        <li><HashLink smooth to="/#ForecastSection"         onClick={toggleMenu}>Daily Horoscopes</HashLink></li>
        <li><HashLink smooth to="/#LoveHoroscopeSection"    onClick={toggleMenu}>Love Horoscope</HashLink></li>
        <li><HashLink smooth to="/#YearAheadReadingSection" onClick={toggleMenu}>Year-Ahead</HashLink></li>
        <li><HashLink smooth to="/#StarSignsSection"        onClick={toggleMenu}>Star Signs</HashLink></li>
        <li><HashLink smooth to="/#TarotSection"            onClick={toggleMenu}>Tarot Readings</HashLink></li>

        <li><HashLink smooth to="/#AboutSection"            onClick={toggleMenu}>About</HashLink></li>
        <li><HashLink smooth to="/#commonQuestions"         onClick={toggleMenu}>Common Questions</HashLink></li>
        <li><HashLink smooth to="/dashboard"                onClick={toggleMenu}>Dashboard</HashLink></li>

        <li><Link onClick={() => { handleSignOut(); toggleMenu(); }}>Sign Out</Link></li>
    </>
    )
}

export default UserMenu
