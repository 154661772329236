
import React                            from 'react'
import { Route, Routes, Navigate }      from 'react-router-dom'

import ProtectedRoute                   from './components/ProtectedRoute'
import GoogleAnalytics                  from './components/GoogleAnalytics'

import LandingPage                      from './pages/LandingPage'
import ForecastPage                     from './pages/ForecastPage'

import PersonalReadingFunnelPage        from './pages/PersonalReadingFunnelPage'
import YearAheadFunnelPage              from './pages/YearAheadFunnelPage'
import LoveCompatibilityFunnelPage      from './pages/LoveCompatibilityFunnelPage'

import TarotPage                        from './pages/TarotPage'
import Tarot3CSFunnelPage               from './pages/Tarot3CSFunnelPage'
import Tarot10CSFunnelPage              from './pages/Tarot10CSFunnelPage'
import Tarot3QuestionsPage              from './pages/Tarot3QuestionsPage'
import CardSelectionPage                from './pages/CardSelectionPage'

import TermsOfServicePage               from './pages/TermsOfServicePage'
import PrivacyPolicyPage                from './pages/PrivacyPolicyPage'

import AuthSuccessPage                  from './pages/AuthSuccessPage'
import PersonalInfoPage                 from './pages/PersonalInfoPage'
import PartnerInfoPage                  from './pages/PartnerInfoPage'
import GetStartDatePage                 from './pages/GetStartDatePage'

import OrderCanceledPage                from './pages/OrderCanceledPage'
import OrderCompletePage                from './pages/OrderCompletePage'

import ConfirmSubscriptionPage          from './pages/ConfirmSubscriptionPage'
import UnsubscribePage                  from './pages/UnsubscribePage'

import RegisterPage                     from './pages/RegisterPage'
import SigninProviderPage               from './pages/SigninProviderPage'
import ConfirmAccountPage               from './pages/ConfirmAccountPage'
import RequestPasswdResetPage           from './pages/RequestPasswdResetPage'
import ResetPasswordPage                from './pages/ResetPasswordPage'
import RefundPolicyPage                 from './pages/RefundPolicyPage'

import StarsignsPage                    from './pages/StarsignsPage'

import DashboardPage                    from './pages/DashboardPage'
import TestFunnelPage                   from './pages/TestFunnelPage'


const AppRoutes = () => (
    <>

    <GoogleAnalytics />

    <Routes>
        <Route path='/'                             element={<LandingPage />}                   />

        <Route path='/signin'                       element={<SigninProviderPage />}            />
        <Route path='/register'                     element={<RegisterPage />}                  />
        <Route path='/confirm-account/:regUuid'     element={<ConfirmAccountPage />}            />
        <Route path='/auth-success'                 element={<AuthSuccessPage />}               />
        <Route path='/request-reset-pass'           element={<RequestPasswdResetPage />}        />
        <Route path='/reset-pass/:resetUuid'        element={<ResetPasswordPage />}             />

        <Route path='/unsubscribe/:subUuid'         element={<UnsubscribePage />}               />

        <Route path='/terms'                        element={<TermsOfServicePage />}            />
        <Route path='/privacy-policy'               element={<PrivacyPolicyPage />}             />
        <Route path='/refund-policy'                element={<RefundPolicyPage />}              />

        <Route path='/star-signs/:starSign?'        element={<StarsignsPage /> }                />
        <Route path='/daily-horoscope/:starSign'    element={<ForecastPage />}                  />
        <Route path="/forecast/:starSign"           element={<ForecastPage /> }                 />

        <Route path='/love-horoscope-reading'       element={<LoveCompatibilityFunnelPage />}   />
        <Route path="/love-compatibility-reading"   element={<LoveCompatibilityFunnelPage />}   />

        <Route path='/personalised-readings'        element={<PersonalReadingFunnelPage />}     />
        <Route path='/year-ahead-reading'           element={<YearAheadFunnelPage />}           />


        {/* Tarot */}
        <Route path='/tarot'                                        element={<TarotPage page={"Index"} />}  />

        {/* Tarot Guide */}
        <Route path="/tarot/guide"                                  element={<TarotPage page={"Guide"} />}  />
        <Route path="/tarot/guide/major-arcana/:cardId?"            element={<TarotPage page={"MajorArcana"} />}    />
        <Route path="/tarot/guide/minor-arcana/:suit?/:cardId?"     element={<TarotPage page={"MinorArcana"} />}    />

        {/* Tarot Readings */}
        <Route path='/tarot/readings'                               element={<TarotPage page={"Readings"} />}   />
        <Route path='/tarot/readings/celtic-cross'                  element={<Tarot10CSFunnelPage />}           />
        <Route path='/tarot/readings/3-card-spread'                 element={<Tarot3CSFunnelPage />}            />
        <Route path='/tarot/readings/3-card-spread/ask-questions'   element={<Tarot3QuestionsPage />}           />
        <Route path='/tarot/readings/3-card-spread/select-cards'    element={<CardSelectionPage numCards={22} numSelect={3} />}  />

        <Route path='/order-complete'               element={<OrderCompletePage />}             />
        <Route path='/order-canceled'               element={<OrderCanceledPage />}             />

        <Route path='/get-personal'                 element={<ProtectedRoute> <PersonalInfoPage />          </ProtectedRoute> }   />
        <Route path='/get-partner'                  element={<ProtectedRoute> <PartnerInfoPage />           </ProtectedRoute> }   />
        <Route path='/get-start-date'               element={<ProtectedRoute> <GetStartDatePage />          </ProtectedRoute> }   />
        <Route path='/confirm-sub'                  element={<ProtectedRoute> <ConfirmSubscriptionPage />   </ProtectedRoute> }   />
        <Route path='/dashboard/:initialTab?'       element={<ProtectedRoute> <DashboardPage />             </ProtectedRoute> }   />

        <Route path='/test-purchase'                element={<ProtectedRoute> <TestFunnelPage />          </ProtectedRoute> }   />

        <Route path="*"                             element={<Navigate to="/" />} />
    </Routes>
    </>
)


export default AppRoutes

