import React from 'react'
import { HashLink } from 'react-router-hash-link'

import Styles from './CtaButton.module.css'

const CtaButton = ({linkText, link}) => {

    return (
        <>
        <div className={Styles.callToAction}>
            <HashLink smooth to={link} className={`${Styles.ctaButton}`}>
                {linkText}
            </HashLink>
        </div>
        </>
    )
}

export default CtaButton
