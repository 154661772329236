import React, { useState, useEffect }   from 'react'
import axios                            from 'axios'
import he                               from 'he'
import Breadcrumb                       from './Breadcrumb'
import Spinner                          from './Spinner'


const CmsContent = ({ type, slug, onContentLoaded }) => {

    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState(null);
    const [title, setTitle]     = useState('');


    useEffect(() => {

        const fetchContent = async () => {
            try
            {
                const url =
                    `https://cms.stargoddess.co/wp-json/wp/v2/${type}s?slug=${slug}`;

                const response = await axios.get(url);

                if (response.data.length > 0)
                {
                    setTitle(he.decode(response.data[0].title.rendered));
                    setContent(he.decode(response.data[0].content.rendered));
                }
            }
            catch (err)
            {
                console.error(`⚠ Exception in CmsContent:\n${err}\n\n`);
            }
            finally
            {
                setLoading (false);

                if (onContentLoaded)
                    onContentLoaded();
            }
        };

        fetchContent();

    }, [type, slug, onContentLoaded]);

    if (loading)
        return ( <><Spinner sizeClass="medium" /></> );

    return (
        <>
            <h1>{title}</h1>
            <Breadcrumb />
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </>
    );
};

export default CmsContent;
