import React                    from 'react'
import { Link, useLocation }    from 'react-router-dom'
import styles                   from './Breadcrumb.module.css'


const Breadcrumb = () => {

    const location      = useLocation();
    const pathSegments  = location.pathname.split("/").filter(Boolean);

    // Create the breadcrumb trail
    const breadcrumbLinks = pathSegments.map((segment, index) => {
        const path          = `/${pathSegments.slice(0, index + 1).join("/")}`;
        const displayName   = segment.replace(/-/g, " ");

        return (
            <React.Fragment key={path}>
                <Link to={path} className={styles.link}> {displayName} </Link>
                {index < pathSegments.length - 1 && <span className={styles.separator}> &gt; </span>}
            </React.Fragment>
        );
    });

    return (
        <>
            <nav className={styles.breadcrumb}>{breadcrumbLinks}</nav>
        </>
    );
};

export default Breadcrumb;
