import React                        from 'react'
import { Helmet }                   from 'react-helmet'

import HeroSection                  from '../components/HeroSection'
import ForecastSection              from '../components/ForecastSection'
import StarsignsSection             from '../components/StarsignsSection'
import LoveHoroscopeSection         from '../components/LoveHoroscopeSection'
import TarotSection                 from '../components/TarotSection'

import YearAheadSection             from '../components/YearAheadSection'
// import PersonalReadingSection       from '../components/PersonalReadingSection'
import AboutSection                 from '../components/AboutSection'
import CommonQuestionsSection       from '../components/CommonQuestionsSection'

import Styles from './LandingPage.module.css'


const LandingPage = () => {

    const title           = 'Personalized Astrology Readings & Horoscopes | StarGoddess Astrology'
    const metaDescription = `Discover celestial insights with StarGoddess! Get free daily horoscopes, tarot readings, love compatibility, and year-ahead forecasts—personalized for you.`
    const websiteUrl      = `https://www.stargoddess.co/`

    return (
    <>
    <Helmet>
        <title>{title}</title>
        <meta name="description"    content={metaDescription} />
        <link rel="canonical"       href={websiteUrl} />
        <meta property="og:url"     content={websiteUrl} />
    </Helmet>

    <div className={Styles.page}>
        <HeroSection />
        <ForecastSection />
        <LoveHoroscopeSection />
        <YearAheadSection />
        <StarsignsSection />
        <TarotSection />

        <AboutSection />
        <CommonQuestionsSection />
    </div>
    </>
    )
};

export default LandingPage
