import React        from 'react'
import CtaButton    from './CtaButton'
import Styles       from './TarotSection.module.css'


const TarotSection = () => {

  return (
    <>
    <section id="TarotSection" className={Styles.tarotSection}>

      <h2 className={Styles.title}>Your Gateway to Tarot Wisdom</h2>

      <div className={Styles.content}>

        <div className={Styles.textContainer}>
          <p>
            Pull the curtain back onto the realm of Tarot and uncover the hidden
            messages woven into every card.  Whether you're a curious seeker or a
            seasoned reader, our growing Tarot library invites you to explore the
            meaning, symbolism, and power of the deck.
          </p>

          <p>
            And this is just the beginning - personalized Tarot readings are
            coming soon!  Stay tuned for an experience that brings the cards
            to life like never before.
          </p>

          <CtaButton link='/tarot' linkText='Visit my Tarot Library' />

        </div>


        <div className={Styles.imageContainer}>
          <img src="/img/3-card-spread.jpg" alt="Tarot Spread" className={Styles.image} />
        </div>

      </div>

    </section>
    </>
  );
};

export default TarotSection;
